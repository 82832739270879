<template>
    <section class="black-section py-5">
        <div class="container">
            <form v-on:submit.prevent="submitForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="title title-big mb-4">
                            {{
                                $t(
                                    "Are you interested in being our next speaker?"
                                )
                            }}
                        </div>
                        <p>
                            {{
                                $t(
                                    "If you’d like to collaborate with us send us a message, write about your expectations and why would you like to work with us and we’ll get back to you with the details."
                                )
                            }}
                        </p>
                    </div>
                    <div class="col-md-5">
                        <div class="form-floating mb-3">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('Firstname')"
                                v-model="form.firstname"
                            />
                            <label>{{ $t("Firstname") }}*</label>
                        </div>
                        <div class="form-floating mb-3">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('Lastname')"
                                v-model="form.lastname"
                            />
                            <label>{{ $t("Lastname") }}*</label>
                        </div>
                        <div class="form-floating mb-4">
                            <input
                                type="email"
                                class="form-control"
                                :placeholder="$t('E-mail')"
                                v-model="form.email"
                            />
                            <label>{{ $t("E-mail") }}*</label>
                        </div>
                        <div class="form-floating mb-3">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('Company name')"
                                v-model="form.company_name"
                            />
                            <label>{{ $t("Company name") }}*</label>
                        </div>
                        <div class="form-floating mb-3">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('Phone number')"
                                v-model="form.phone_number"
                            />
                            <label>{{ $t("Phone number") }}*</label>
                        </div>
                        <div class="form-floating mb-3">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('Instagram user name')"
                                v-model="form.instagram_user"
                            />
                            <label>{{ $t("Instagram user name") }}</label>
                        </div>
                        <div class="form-floating mb-3">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('Facebook page link')"
                                v-model="form.facebook_page"
                            />
                            <label>{{ $t("Facebook page link") }}</label>
                        </div>
                        <div class="form-floating mb-3">
                            <textarea
                                class="form-control"
                                :placeholder="$t('Message')"
                                rows="6"
                                v-model="form.message"
                            ></textarea>
                            <label>{{ $t("Message") }}*</label>
                        </div>
                        <div class="form-check mb-3">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value="1"
                                id="flexCheckDefault"
                                v-model="form.aszf"
                            />
                            <label
                                class="form-check-label"
                                for="flexCheckDefault"
                            >
                                {{
                                    $t(
                                        "I’ve read and accepted the terms and conditions."
                                    )
                                }}
                            </label>
                        </div>
                        <div class="d-grid">
                            <button class="btn btn-outline" type="submit">
                                {{ $t("Send") }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import BaseSubscribe from "@/components/BaseSubscribe";

export default {
    name: "ContactFormSpeaker",
    extends: BaseSubscribe,
    beforeMount() {
        this.endPoint = `/subscribe/speakers`;
    }
};
</script>

<style scoped></style>
