<template>
    <section class="border-bottom pb-5">
        <div class="container">
            <div class="title title-big my-5">{{ $t("Most popular") }}</div>
            <div class="row">
                <div
                    class="col-sm-6 col-lg-3"
                    v-for="(item, index) in model"
                    v-bind:key="'most-popular-' + index"
                >
                    <div class="article-box my-4 mt-lg-0">
                        <div class="aspect43">
                            <b-img-lazy :src="item.main_image" />
                        </div>
                        <div class="mt-2">{{ item.publication_date }}</div>
                        <h3 class="title title-normal my-2">
                            {{ item.title }}
                        </h3>
                        <router-link
                            :to="{
                                name: 'Article',
                                params: { slug: item.slug }
                            }"
                            class="stretched-link"
                        ></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "MostPopularNewsBlock",
    props: ["model"]
};
</script>

<style scoped></style>
