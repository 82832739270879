<template>
    <div></div>
</template>

<script>
export default {
    name: "BaseArticle",
    data() {
        return {
            model: {}
        };
    },
    beforeMount() {
        this.getPage();
    },
    watch: {
        $route: {
            handler: function() {
                this.getPage();
            },
            deep: true
        }
    }
};
</script>
