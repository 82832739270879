<template>
    <div class="master-designers">
        <section>
            <div class="container">
                <div class="title-block title-block-no-top">
                    <div class="title title-big">
                        {{ $t("About Master Designers") }}
                    </div>
                </div>
                <div class="row">
                    <div
                        class="col-md-4 my-4"
                        v-for="(item, index) in model"
                        v-bind:key="'designer-' + index"
                    >
                        <div class="position-relative">
                            <div class="aspect11">
                                <b-img-lazy :src="item.main_image" />
                            </div>
                            <div class="title title-normal mt-3">
                                {{ item.name }}
                            </div>
                            <router-link
                                :to="{
                                    name: 'Designer',
                                    params: { slug: item.slug }
                                }"
                                class="stretched-link"
                                >{{ $t("View") }}</router-link
                            >
                        </div>
                    </div>
                </div>
                <div class="pager text-center">
                    <router-link :to="previousPage"
                        ><img src="/img/arrow_left.svg" class="arrow" />
                    </router-link>
                    {{ meta.current_page }} / {{ meta.last_page }}
                    <router-link :to="nextPage"
                        ><img src="/img/arrow_right.svg" class="arrow" />
                    </router-link>
                </div>
            </div>
        </section>
        <ContactFormSpeaker />
        <ContactFormPresent />
    </div>
</template>

<script>
import ContactFormSpeaker from "@/components/ContactFormSpeaker";
import ContactFormPresent from "@/components/ContactFormPresent";
import BaseNews from "@/components/BaseNews";
import ApiService from "@/core/services/api.service";
export default {
    name: "MasterDesigners",
    extends: BaseNews,
    components: { ContactFormPresent, ContactFormSpeaker },
    methods: {
        getModel(page = 1) {
            ApiService.query(`/designer/get?page=${page}`).then(response => {
                this.meta = response.data.meta;
                this.model = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
