import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/search",
        name: "Search",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "search" */ "../views/Search.vue")
    },
    {
        path: "/charity/:slug",
        name: "Charity",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "charity" */ "../views/Charity.vue")
    },
    {
        path: "/article/:slug",
        name: "Article",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "article" */ "../views/Article.vue")
    },
    {
        path: "/event/:slug",
        name: "EventPage",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "event-page" */ "../views/EventPage.vue"
            )
    },
    {
        path: "/tender/:slug",
        name: "Tender",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "tender" */ "../views/TenderPage.vue")
    },
    {
        path: "/designer/:slug",
        name: "Designer",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "designer" */ "../views/Designer.vue")
    },
    {
        path: "/404",
        name: "NotFound",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue")
    },
    {
        path: "*",
        name: "Static",
        component: () =>
            import(/* webpackChunkName: "static" */ "../components/Static.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes
});

export default router;
