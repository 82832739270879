var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.model.length > 0)?_c('div',[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"row align-items-center gx-0"},[_c('div',{staticClass:"col-lg-6 order-2 order-lg-1"},[_c('div',{staticClass:"black-section p-4"},[_c('div',{staticClass:"eventlistSlider-content"},_vm._l((_vm.model),function(item,index){return _c('div',{key:'ev-' + index},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(item.start_date)+" ")]),_c('div',{staticClass:"title title-normal"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"my-4",domProps:{"innerHTML":_vm._s(item.lead)}}),_c('div',{staticClass:"title title-small"},[_vm._v(" "+_vm._s(_vm.$t("Performers"))+" ")]),_vm._l((item.performers),function(item2,index2){return [_c('router-link',{key:'perf-' + index + '-' + index2,attrs:{"to":{
                                            name: 'Designer',
                                            params: { slug: item2.slug }
                                        }}},[_vm._v(_vm._s(item2.name))]),_c('br',{key:'perf-br-' +
                                                index +
                                                '-' +
                                                index2})]}),(item.join_link)?_c('div',{staticClass:"d-grid mt-5"},[_c('router-link',{staticClass:"btn btn-outline",attrs:{"to":{
                                            name: 'EventPage',
                                            params: { slug: item.slug }
                                        }}},[_vm._v(" "+_vm._s(_vm.$t("Sign up"))+" ")])],1):_vm._e()],2)}),0),_c('div',{staticClass:"eventlistSlider-nav mt-4"})])]),_c('div',{staticClass:"col-lg-6 order-1 order-lg-2"},[_c('div',{staticClass:"eventlistSlider"},_vm._l((_vm.model),function(item,index){return _c('div',{key:'ev-img-' + index},[_c('div',{staticClass:"aspect11"},[_c('b-img',{staticClass:"img img-fluid",attrs:{"src":item.image}})],1)])}),0)])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"mb-2 mt-4 mt-lg-0"},[_vm._v(_vm._s(_vm.$t("Past events")))]),_c('ul',{staticClass:"events-list"},_vm._l((_vm.past),function(item,index){return _c('li',{key:'p-e-' + index},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.start_date))]),_c('div',{staticClass:"title title-small"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{
                            name: 'EventPage',
                            params: { slug: item.slug }
                        }}})],1)}),0)])]),_c('div',{staticClass:"row pb-5"},[_c('div',{staticClass:"col-md-4 offset-md-8"},[_c('div',{staticClass:"text-lg-end mt-4 mt-lg-0"},[_c('router-link',{attrs:{"to":"/events"}},[_vm._v(_vm._s(_vm.$t("View all")))])],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }