<template>
    <div v-if="model.length > 0">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="row align-items-center gx-0">
                    <div class="col-lg-6 order-2 order-lg-1">
                        <div class="black-section p-4">
                            <div class="eventlistSlider-content">
                                <div
                                    v-for="(item, index) in model"
                                    v-bind:key="'ev-' + index"
                                >
                                    <div class="date">
                                        {{ item.start_date }}
                                    </div>
                                    <div class="title title-normal">
                                        {{ item.title }}
                                    </div>

                                    <p class="my-4" v-html="item.lead"></p>

                                    <div class="title title-small">
                                        {{ $t("Performers") }}
                                    </div>
                                    <template
                                        v-for="(item2,
                                        index2) in item.performers"
                                    >
                                        <router-link
                                            v-bind:key="
                                                'perf-' + index + '-' + index2
                                            "
                                            :to="{
                                                name: 'Designer',
                                                params: { slug: item2.slug }
                                            }"
                                            >{{ item2.name }}</router-link
                                        >
                                        <br
                                            v-bind:key="
                                                'perf-br-' +
                                                    index +
                                                    '-' +
                                                    index2
                                            "
                                        />
                                    </template>

                                    <div
                                        class="d-grid mt-5"
                                        v-if="item.join_link"
                                    >
                                        <router-link
                                            class="btn btn-outline"
                                            :to="{
                                                name: 'EventPage',
                                                params: { slug: item.slug }
                                            }"
                                        >
                                            {{ $t("Sign up") }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                            <div class="eventlistSlider-nav mt-4"></div>
                        </div>
                    </div>
                    <div class="col-lg-6 order-1 order-lg-2">
                        <div class="eventlistSlider">
                            <div
                                v-for="(item, index) in model"
                                v-bind:key="'ev-img-' + index"
                            >
                                <div class="aspect11">
                                    <b-img
                                        class="img img-fluid"
                                        :src="item.image"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="mb-2 mt-4 mt-lg-0">{{ $t("Past events") }}</div>
                <ul class="events-list">
                    <li
                        v-for="(item, index) in past"
                        v-bind:key="'p-e-' + index"
                    >
                        <div class="date">{{ item.start_date }}</div>
                        <div class="title title-small">
                            {{ item.title }}
                        </div>
                        <router-link
                            :to="{
                                name: 'EventPage',
                                params: { slug: item.slug }
                            }"
                            class="stretched-link"
                        ></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row pb-5">
            <div class="col-md-4 offset-md-8">
                <div class="text-lg-end mt-4 mt-lg-0">
                    <router-link to="/events">{{ $t("View all") }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseNews from "@/components/BaseNews";
import ApiService from "@/core/services/api.service";

export default {
    name: "EventsBlock",
    extends: BaseNews,
    data() {
        return {
            past: []
        };
    },
    beforeMount() {
        this.getPast();
    },
    methods: {
        getModel(page = 1) {
            ApiService.query(`/event/next?page=${page}`)
                .then(response => {
                    this.meta = response.data.meta;
                    this.model = response.data.data;
                })
                .finally(() => {
                    $(document).ready(function() {
                        $(".eventlistSlider-content").slick({
                            dots: true,
                            arrows: false,
                            infinite: true,
                            autoplay: true,
                            slidesToScroll: 1,
                            slidesToShow: 1,
                            appendDots: ".eventlistSlider-nav",
                            asNavFor: ".eventlistSlider"
                        });
                        $(".eventlistSlider").slick({
                            arrows: false,
                            asNavfor: ".eventlistSlider-content"
                        });
                    });
                });
        },
        getPast(page = 1) {
            ApiService.query(`/event/past?page=${page}&per_page=5`).then(
                response => {
                    this.past = response.data.data;
                }
            );
        }
    }
};
</script>
