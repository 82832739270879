import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import VueCookies from "vue-cookies";
import jQuery from "jquery";
import i18n from "../../admin/src/core/plugins/vue-i18n";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "toastr/build/toastr.min.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import VueSlickCarousel from "vue-slick-carousel";
import "./assets/custom.css";
import mixins from "./mixins";
import slick from "slick-carousel";
import "slick-carousel/slick/slick.css";

Vue.config.productionTip = false;
Vue.prototype.$translate = i18n;
Vue.prototype.$eventHub = new Vue();
Vue.prototype.$ = jQuery;
Vue.prototype.$.slick = slick;
window.$ = jQuery;

// API service init
ApiService.init();

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueSlickCarousel);

Vue.mixin(mixins);

const files = require.context("./views/", true, /\.vue$/i);
files.keys().map(key =>
    Vue.component(
        key
            .split("/")
            .pop()
            .split(".")[0],
        files(key).default
    )
);

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
    // Scroll page to top on every route change
    next();
    setTimeout(() => {
        if (
            to.name !== "EventPage" &&
            to.name !== "Charity" &&
            to.name !== "Article" &&
            to.name !== "Tender" &&
            to.name !== "Designer"
        ) {
            window.scrollTo(0, 0);
        }
        $(".menu").removeClass("active");
    }, 100);
});

new Vue({
    router,
    store,
    i18n,
    VueCookies,
    render: h => h(App)
}).$mount("#app");
