<template>
    <div class="events-slider mt-5">
        <div class="container" v-if="model.length > 0">
            <div class="row gx-0">
                <div class="col-xl-8">
                    <div class="mainSlider">
                        <div
                            v-for="(item, index) in model"
                            v-bind:key="'slider-img-' + index"
                        >
                            <div class="aspect32">
                                <b-img
                                    :src="item.image"
                                    class="img img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 black-section">
                    <div class="p-4">
                        <div class="mainSlider-content">
                            <div
                                v-for="(item, index) in model"
                                v-bind:key="'slider-content-' + index"
                            >
                                <div class="date">
                                    {{ item.start_date }}
                                </div>
                                <div class="title title-normal mb-4">
                                    {{ item.title }}
                                </div>

                                <div class="title title-small">
                                    {{ $t("Performers") }}
                                </div>
                                <template
                                    v-for="(item2, index2) in item.performers"
                                >
                                    <router-link
                                        v-bind:key="
                                            'main-perf-' + index + '-' + index2
                                        "
                                        :to="{
                                            name: 'Designer',
                                            params: { slug: item2.slug }
                                        }"
                                        >{{ item2.name }}</router-link
                                    >
                                    <br
                                        v-bind:key="
                                            'main-perf-br-' +
                                                index +
                                                '-' +
                                                index2
                                        "
                                    />
                                </template>
                                <Countdown
                                    :date="item.start_date_rfc"
                                    v-if="item.start_date_rfc"
                                />
                                <div class="d-grid" v-if="item.join_link">
                                    <router-link
                                        class="btn btn-primary"
                                        :to="{
                                            name: 'EventPage',
                                            params: { slug: item.slug }
                                        }"
                                    >
                                        {{ $t("Join now") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="mainSlider-nav mt-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BaseNews from "@/components/BaseNews";
import Countdown from "@/components/Countdown";

export default {
    name: "EventSlider",
    components: { Countdown },
    extends: BaseNews,
    methods: {
        getModel(page = 1) {
            ApiService.query(`/event/next?page=${page}`)
                .then(response => {
                    this.meta = response.data.meta;
                    this.model = response.data.data;
                })
                .finally(() => {
                    $(document).ready(function() {
                        $(".mainSlider-content").slick({
                            dots: true,
                            arrows: false,
                            infinite: true,
                            autoplay: true,
                            slidesToScroll: 1,
                            slidesToShow: 1,
                            appendDots: ".mainSlider-nav",
                            asNavFor: ".mainSlider"
                        });
                        $(".mainSlider").slick({
                            arrows: false,
                            asNavFor: ".mainSlider-content"
                        });
                    });
                });
        }
    }
};
</script>
