<template>
    <section class="py-5">
        <div class="container">
            <div class="title title-big mb-3">{{ $t("Past events") }}</div>

            <div class="row">
                <router-link
                    :to="{
                        name: 'EventPage',
                        params: { slug: item.slug }
                    }"
                    tag="div"
                    class="col-md-2"
                    style="cursor: pointer"
                    v-for="(item, index) in model"
                    v-bind:key="'past-events-' + index"
                >
                    <div class="aspect11">
                        <b-img-lazy :src="item.image" />
                    </div>
                    <div class="mt-2">{{ item.start_date }}</div>
                    <h3 class="title title-small my-3">
                        {{ item.title }}
                    </h3>
                </router-link>
            </div>

            <div class="pager text-center">
                <router-link :to="previousPage"
                    ><img src="/img/arrow_left.svg" class="arrow" />
                </router-link>
                {{ meta.current_page }} / {{ meta.last_page }}
                <router-link :to="nextPage"
                    ><img src="/img/arrow_right.svg" class="arrow" />
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import BaseNews from "@/components/BaseNews";
import ApiService from "@/core/services/api.service";

export default {
    name: "PastEvents",
    extends: BaseNews,
    methods: {
        getModel(page = 1) {
            ApiService.query(`/event/past?page=${page}&per_page=6`).then(
                response => {
                    this.meta = response.data.meta;
                    this.model = response.data.data;
                }
            );
        }
    }
};
</script>

<style scoped></style>
