<template>
    <div class="home">
        <EventSlider />
        <section>
            <div class="container">
                <div class="title-block">
                    <h2 class="title title-big">{{ $t("Latest News") }}</h2>
                </div>
                <LatestNewsBlock />
            </div>
        </section>
        <section>
            <div class="container">
                <div class="title-block">
                    <h2 class="title title-big">
                        {{ $t("Master Designers Trailers") }}
                    </h2>
                </div>
                <MasterDesignersTrailersBlock />
            </div>
        </section>
        <LatestNewsSubscribeBlock />
        <section class="md-section">
            <div class="container">
                <div class="title-block title-block-no-top">
                    <h2 class="title title-big">
                        {{ $t("Master Designers") }}
                    </h2>
                </div>
                <MasterDesignersBlock />
                <div class="title-block">
                    <h2 class="title title-big">{{ $t("Events") }}</h2>
                </div>
                <EventsBlock />
            </div>
        </section>
        <MissEvent />
        <TendersBlock />
        <AboutUsBlock :page-data="pageData" />
        <ContactFormSpeaker />
        <ContactFormPresent />
    </div>
</template>

<script>
import EventSlider from "@/components/EventSlider";
import LatestNewsBlock from "@/components/LatestNewsBlock";
import MasterDesignersTrailersBlock from "@/components/MasterDesignersTrailersBlock";
import LatestNewsSubscribeBlock from "@/components/LatestNewsSubscribeBlock";
import EventsBlock from "@/components/EventsBlock";
import MissEvent from "@/components/MissEvent";
import TendersBlock from "@/components/TendersBlock";
import AboutUsBlock from "@/components/AboutUsBlock";
import ContactFormSpeaker from "@/components/ContactFormSpeaker";
import ContactFormPresent from "@/components/ContactFormPresent";
import MasterDesignersBlock from "@/components/MasterDesignersBlock";
import Static from "@/components/Static";
export default {
    name: "Home",
    extends: Static,
    components: {
        MasterDesignersBlock,
        ContactFormPresent,
        ContactFormSpeaker,
        AboutUsBlock,
        TendersBlock,
        MissEvent,
        EventsBlock,
        LatestNewsSubscribeBlock,
        MasterDesignersTrailersBlock,
        LatestNewsBlock,
        EventSlider
    }
};
</script>
