<template>
    <section class="marble-section py-5">
        <div class="container">
            <div class="title-block">
                <div class="title title-big mb-3">{{ $t("Speakers") }}</div>
            </div>
            <div class="row">
                <div
                    class="col-md-4"
                    v-for="(item, index) in model"
                    v-bind:key="'speakers-' + index"
                >
                    <div class="position-relative">
                        <div class="aspect11">
                            <b-img-lazy :src="item.main_image" />
                        </div>
                        <div class="title title-normal my-3">
                            {{ item.name }}
                        </div>
                        <p class="text-justify mt-0" v-html="item.lead"></p>
                        <router-link
                            :to="{
                                name: 'Designer',
                                params: { slug: item.slug }
                            }"
                            class="stretched-link"
                            >{{ $t("Read more") }}</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Speakers",
    props: ["model"]
};
</script>

<style scoped></style>
