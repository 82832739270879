<template>
    <div>
        <section>
            <div class="container">
                <div class="title-block title-block-no-top">
                    <div class="title title-big mb-3">{{ $t("Events") }}</div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="title title-big mb-5">
                            {{ model.title }}
                        </div>
                        <p v-html="model.lead"></p>
                    </div>
                    <div class="col-md-4 offset-md-2">
                        <strong
                            >{{ $t("Date") }}:
                            {{ model.start_date_day }}</strong
                        >
                        <br />
                        {{ $t("Time") }}: {{ model.start_date_hour }} <br />
                        {{ $t("Event duration") }}: {{ model.event_duration }}

                        <Countdown
                            :date="model.start_date_rfc"
                            v-if="model.start_date_rfc"
                        />
                        <div class="d-grid" v-if="model.join_link">
                            <a
                                :href="model.join_link"
                                target="_blank"
                                class="btn btn-primary"
                                >{{ $t("Join now") }}</a
                            >
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 my-5">
                        <div class="aspect169">
                            <b-img-lazy :src="model.image" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <article v-html="model.content"></article>

                        <div class="row my-5" v-if="model.join_link">
                            <div class="col-md-4 offset-md-4">
                                <div class="d-grid">
                                    <a
                                        :href="model.join_link"
                                        target="_blank"
                                        class="btn btn-primary"
                                        >{{ $t("Join now") }}</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Speakers :model="model.performers" v-if="model.performers" />
        <PastEvents />
        <MissEvent />
    </div>
</template>

<script>
import Speakers from "@/components/Speakers";
import PastEvents from "@/components/PastEvents";
import MissEvent from "@/components/MissEvent";
import BaseArticle from "@/components/BaseArticle";
import ApiService from "@/core/services/api.service";
import Countdown from "@/components/Countdown";

export default {
    name: "EventPage",
    extends: BaseArticle,
    components: { Countdown, MissEvent, PastEvents, Speakers },
    methods: {
        getPage() {
            ApiService.query(`/event/get/${this.$route.params.slug}`).then(
                resp => {
                    this.model = resp.data.data;
                    this.metaCreation(this.model);
                }
            );
        }
    }
};
</script>

<style scoped></style>
