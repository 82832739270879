<template>
    <section class="border-bottom">
        <div class="container">
            <div class="title-block title-block-no-top">
                <h2 class="title title-big">Tenders</h2>
            </div>
            <div class="row">
                <div
                    class="col-lg-4"
                    v-for="(item, index) in model"
                    v-bind:key="'tenders-' + index"
                >
                    <div class="article-box my-4 mt-lg-0">
                        <div class="aspect43">
                            <b-img-lazy :src="item.image" />
                        </div>

                        <div class="mt-2">{{ item.publication_date }}</div>
                        <h3 class="title title-normal my-3">
                            {{ item.name }}
                        </h3>

                        <p
                            class="text-justify mt-3 mb-1"
                            v-html="item.lead"
                        ></p>
                        <router-link
                            :to="{
                                name: 'Tender',
                                params: { slug: item.slug }
                            }"
                            class="stretched-link"
                            >{{ $t("Read more") }}</router-link
                        >
                    </div>
                </div>
            </div>
            <div class="pager text-center">
                <router-link :to="previousPage"
                    ><img src="/img/arrow_left.svg" class="arrow" />
                </router-link>
                {{ meta.current_page }} / {{ meta.last_page }}
                <router-link :to="nextPage"
                    ><img src="/img/arrow_right.svg" class="arrow" />
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BaseNews from "@/components/BaseNews";

export default {
    name: "Tenders",
    extends: BaseNews,
    methods: {
        getModel(page = 1) {
            ApiService.query(`/tender/get?page=${page}`).then(response => {
                this.meta = response.data.meta;
                this.model = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
