<template>
    <div class="row mb-5" v-if="model.length > 0">
        <div class="col-lg-8">
            <div class="trailersSlider-video">
                <div
                    v-for="(item, index) in model"
                    v-bind:key="'video-' + index"
                >
                    <div class="aspect169">
                        <b-embed
                            style="max-width: 100%;"
                            type="video"
                            aspect="16by9"
                            controls
                            :poster="item.trailer_image"
                        >
                            <source :src="item.trailer" type="video/mp4" />
                        </b-embed>
                    </div>
                    <div class="row align-items-center my-4">
                        <div class="col-auto">
                            <div class="title title-normal">
                                {{ item.name }}
                            </div>
                        </div>
                        <div class="col text-end">
                            <router-link
                                :to="{
                                    name: 'Designer',
                                    params: { slug: item.slug }
                                }"
                                class="btn btn-outline"
                                >{{ $t("Learn more") }}</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="row align-items-center">
                <div class="col">
                    <div class="mb-2">{{ $t("More Trailers") }}</div>
                </div>
                <div class="col-auto">
                    <div class="trailersSlider-nav"></div>
                </div>
            </div>

            <div class="trailersSlider">
                <div
                    v-for="(item, index) in trailersList"
                    v-bind:key="'tariler-list-item-' + index"
                >
                    <div class="trailers-list">
                        <div
                            v-for="(item2, index2) in item"
                            v-bind:key="'trailer-sub-' + index2"
                        >
                            {{ item2.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseNews from "@/components/BaseNews";
import ApiService from "@/core/services/api.service";

export default {
    name: "MasterDesignersTrailersBlock",
    extends: BaseNews,
    computed: {
        trailersList: function() {
            let list = [];
            let index = 0;
            let innerIndex = 0;
            for (const it in this.model) {
                if (!list[innerIndex]) {
                    list[innerIndex] = [];
                }
                list[innerIndex].push(this.model[it]);
                index++;
                if (index > 6) {
                    innerIndex++;
                    index = 0;
                }
            }

            return list;
        }
    },
    methods: {
        getModel(page = 1) {
            ApiService.query(`/designer/get?page=${page}`)
                .then(response => {
                    this.meta = response.data.meta;
                    this.model = response.data.data;
                })
                .finally(() => {
                    $(document).ready(function() {
                        $(".trailersSlider").slick({
                            dots: true,
                            arrows: false,
                            infinite: true,
                            autoplay: false,
                            slidesToScroll: 1,
                            slidesToShow: 1,
                            appendDots: ".trailersSlider-nav"
                        });
                        $(".trailers-list").slick({
                            arrows: false,
                            infinite: true,
                            autoplay: false,
                            slidesToScroll: 7,
                            slidesToShow: 7,
                            asNavFor: ".trailersSlider-video",
                            focusOnSelect: true
                        });
                        $(".trailersSlider-video").slick({
                            arrows: false
                            //dots: true
                        });
                    });
                });
        }
    }
};
</script>
