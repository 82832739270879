<template>
    <section>
        <div class="container">
            <div class="title-block">
                <h2 class="title title-big">{{ $t("About us") }}</h2>
            </div>
            <div class="row">
                <div class="col-md-6 offset-md-3">
                    <p class="text-justify" v-html="pageData.content"></p>
                    <router-link to="/about">{{ $t("Read more") }}</router-link>
                </div>
            </div>
            <div class="row" v-if="pageData.og_image">
                <div class="col-md-8 offset-md-2 my-4">
                    <div class="aspect169">
                        <b-img-lazy :src="pageData.og_image" />
                    </div>
                </div>
            </div>
            <ContactUsBlock />
        </div>
    </section>
</template>

<script>
import ContactUsBlock from "@/components/ContactUsBlock";
export default {
    name: "AboutUsBlock",
    props: ["pageData"],
    components: { ContactUsBlock }
};
</script>

<style scoped></style>
