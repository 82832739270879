<template>
    <header>
        <div class="container position-relative">
            <div class="row gx-0 align-items-end">
                <div class="col-xl-2">
                    <div class="black-section d-none d-xl-block">
                        <router-link to="/">
                            <b-img-lazy src="/img/logo.svg" class="logo" />
                        </router-link>
                    </div>
                    <router-link to="/" class="d-block d-xl-none">
                        <b-img-lazy src="/img/md_logo_black.svg" class="logo" />
                    </router-link>
                </div>
                <div class="col-xl-10">
                    <div class="row top-row">
                        <div class="col">
                            <a
                                href="#"
                                v-on:click.prevent="selectedLanguage('en')"
                                v-if="activeLanguage === 'hu'"
                                >EN</a
                            >
                            <a
                                href="#"
                                v-on:click.prevent="selectedLanguage('hu')"
                                v-else
                                >HU</a
                            >
                        </div>
                        <div class="col-auto text-end">
                            <a
                                href="https://www.instagram.com/vesta_home_master_designers/"
                                target="_blank"
                                ><b-img-lazy
                                    src="/img/insta_w.svg"
                                    class="social-icon"
                                />
                            </a>
                            <a
                                href="https://www.facebook.com/vestahome.masterdesigners"
                                target="_blank"
                                ><b-img-lazy
                                    src="/img/facebook_w.svg"
                                    class="social-icon"
                                />
                            </a>
                            <a
                                href="https://www.youtube.com/watch?v=Vxr8sEflcgU&list=PLVvA2jUjZsyUrf3M-I_CWKx6MjKJhYsA_&ab_channel=VestaHomeOfficial"
                                target="_blank"
                                ><b-img-lazy
                                    src="/img/youtube_w.svg"
                                    class="social-icon"
                                />
                            </a>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col menu-holder">
                            <ul class="menu">
                                <li
                                    v-for="(item, index) in menu.items"
                                    v-bind:key="'head-menu-' + index"
                                >
                                    <router-link :to="item.link">{{
                                        item.title
                                    }}</router-link>
                                </li>
                            </ul>
                            <div class="open-mobile-nav">
                                <div class="mobile-nav-icon ml-auto">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto text-end search-area">
                            <div class="search-icon">
                                <img src="/img/search.svg" class="w-100" />
                            </div>
                            <div class="search-bar">
                                <form
                                    class="w-100"
                                    v-on:submit.prevent="navigateToSearch"
                                >
                                    <input
                                        type="text"
                                        name="search"
                                        class="form-control"
                                        :placeholder="$t('Keresés') + '...'"
                                        v-model="search"
                                    />
                                </form>
                            </div>
                            <div class="search-close">
                                X
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import ApiService from "@/core/services/api.service";
import i18nService from "../../../admin/src/core/services/i18n.service";

export default {
    name: "Header",
    data() {
        return {
            menu: {
                items: []
            },
            languages: i18nService.languages,
            search: null
        };
    },
    beforeMount() {
        ApiService.get(`/menu/get/1`).then(resp => {
            this.menu = resp.data.data;
        });
    },
    methods: {
        selectedLanguage(lang) {
            i18nService.setActiveLanguage(lang);

            window.location.reload();
        },
        navigateToSearch() {
            if (this.search) {
                this.$router.replace({
                    name: "Search",
                    query: { s: this.search }
                });
            }
        }
    },
    computed: {
        activeLanguage() {
            return i18nService.getActiveLanguage();
        }
    },
    mounted() {
        $(document).ready(function() {
            $(".search-icon").click(function() {
                $("header").addClass("search-active");
            });
            $(".search-close").click(function() {
                $("header").removeClass("search-active");
            });

            $(".open-mobile-nav").click(function() {
                $(".mobile-nav-icon").toggleClass("active");
                $(".menu").toggleClass("active");
            });
        });
    }
};
</script>

<style scoped></style>
