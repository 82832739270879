var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events-slider mt-5"},[(_vm.model.length > 0)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row gx-0"},[_c('div',{staticClass:"col-xl-8"},[_c('div',{staticClass:"mainSlider"},_vm._l((_vm.model),function(item,index){return _c('div',{key:'slider-img-' + index},[_c('div',{staticClass:"aspect32"},[_c('b-img',{staticClass:"img img-fluid",attrs:{"src":item.image}})],1)])}),0)]),_c('div',{staticClass:"col-xl-4 black-section"},[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"mainSlider-content"},_vm._l((_vm.model),function(item,index){return _c('div',{key:'slider-content-' + index},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(item.start_date)+" ")]),_c('div',{staticClass:"title title-normal mb-4"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"title title-small"},[_vm._v(" "+_vm._s(_vm.$t("Performers"))+" ")]),_vm._l((item.performers),function(item2,index2){return [_c('router-link',{key:'main-perf-' + index + '-' + index2,attrs:{"to":{
                                        name: 'Designer',
                                        params: { slug: item2.slug }
                                    }}},[_vm._v(_vm._s(item2.name))]),_c('br',{key:'main-perf-br-' +
                                            index +
                                            '-' +
                                            index2})]}),(item.start_date_rfc)?_c('Countdown',{attrs:{"date":item.start_date_rfc}}):_vm._e(),(item.join_link)?_c('div',{staticClass:"d-grid"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                                        name: 'EventPage',
                                        params: { slug: item.slug }
                                    }}},[_vm._v(" "+_vm._s(_vm.$t("Join now"))+" ")])],1):_vm._e()],2)}),0),_c('div',{staticClass:"mainSlider-nav mt-3"})])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }