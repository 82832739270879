import i18n from "@/core/config/i18n";

const i18nService = {
    defaultLanguage: "hu",

    languages: [
        {
            lang: "hu",
            name: i18n.t("magyar"),
            flag: process.env.BASE_URL + "media/svg/flags/115-hungary.svg"
        },
        {
            lang: "en",
            name: i18n.t("English"),
            flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg"
        }
    ],

    /**
     * Keep the active language in the localStorage
     * @param lang
     */
    setActiveLanguage(lang) {
        localStorage.setItem("language", lang);
    },

    /**
     * Get the current active language
     * @returns {string | string}
     */
    getActiveLanguage() {
        return localStorage.getItem("language") || this.defaultLanguage;
    }
};

export default i18nService;
