<template>
    <div></div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
    name: "BaseNews",
    // eslint-disable-next-line vue/no-unused-components
    components: { VueSlickCarousel },
    data() {
        return {
            upperTwo: [],
            model: [],
            meta: {}
        };
    },
    computed: {
        previousPage() {
            const page =
                this.meta.current_page > 1 ? this.meta.current_page - 1 : 1;

            return this.$route.path + "?page=" + page;
        },
        nextPage() {
            const page =
                this.meta.current_page < this.meta.last_page
                    ? this.meta.current_page + 1
                    : this.meta.last_page;

            return this.$route.path + "?page=" + page;
        }
    },
    watch: {
        $route: {
            handler: function(val, oldVal) {
                if (val.path === oldVal.path) {
                    this.getModel(val.query.page);
                }
            },
            deep: true
        }
    },
    beforeMount() {
        const page = this.$route.query.page ? this.$route.query.page : 1;
        this.getModel(page);
    }
};
</script>
