<template>
    <div class="article">
        <section>
            <div class="container">
                <div class="title-block title-block-no-top">
                    <div class="title title-big">{{ $t("News") }}</div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-5 text-center">
                        <div class="title title-big">{{ model.title }}</div>
                        <div class="date mb-5">
                            {{ model.publication_date }}
                        </div>
                        <div class="aspect169">
                            <b-img-lazy :src="model.main_image" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8 offset-xl-2">
                        <div class="lead" v-html="model.lead"></div>
                        <article v-html="model.content"></article>

                        <a href="#" target="_blank" v-on:click.prevent="shareFb"
                            ><img src="/img/share.png" height="40" class="my-5"
                        /></a>
                    </div>
                </div>
            </div>
        </section>
        <LatestNewsSubscribeBlock />
        <MostPopularNewsBlock :model="model.connected" />
    </div>
</template>

<script>
import LatestNewsSubscribeBlock from "@/components/LatestNewsSubscribeBlock";
import MostPopularNewsBlock from "@/components/MostPopularNewsBlock";
import ApiService from "@/core/services/api.service";
import BaseArticle from "@/components/BaseArticle";

export default {
    name: "Article",
    extends: BaseArticle,
    components: { MostPopularNewsBlock, LatestNewsSubscribeBlock },
    methods: {
        shareFb() {
            FB.ui(
                {
                    method: "share",
                    href: document.location.href
                },
                function() {}
            );
        },
        getPage() {
            ApiService.query(`/blog/get/${this.$route.params.slug}`).then(
                resp => {
                    this.model = resp.data.data;
                    this.metaCreation(this.model);
                }
            );
        }
    }
};
</script>

<style scoped></style>
