<template>
    <section class="black-section py-5">
        <div class="container">
            <form v-on:submit.prevent="submitForm">
                <div class="row">
                    <div class="col-md-4">
                        <div class="title title-big">
                            {{ $t("Do not miss our latest Events!") }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-floating mb-3">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('Firstname')"
                                v-model="form.firstname"
                            />
                            <label>{{ $t("Firstname") }}</label>
                        </div>
                        <div class="form-floating mb-3">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('Lastname')"
                                v-model="form.lastname"
                            />
                            <label>{{ $t("Lastname") }}</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-floating mb-4">
                            <input
                                type="email"
                                class="form-control"
                                :placeholder="$t('E-mail')"
                                v-model="form.email"
                            />
                            <label>{{ $t("E-mail") }}</label>
                        </div>
                        <div class="d-grid">
                            <button class="btn btn-outline" type="submit">
                                {{ $t("Sign up") }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import BaseSubscribe from "@/components/BaseSubscribe";

export default {
    name: "MissEvent",
    extends: BaseSubscribe,
    beforeMount() {
        this.endPoint = `/subscribe/latest-events`;
    }
};
</script>

<style scoped></style>
