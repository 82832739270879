import Vue from "vue";
//import store from "../store";
import VueI18n from "vue-i18n";
window.Vue = Vue;

//const messages = store.state.defaultStore.languages;
const messages = { hu: {}, en: {} };

Vue.use(VueI18n);

let language = "hu";
if (localStorage.getItem("language")) {
    language = localStorage.getItem("language");
}

export default new VueI18n({
    locale: language, // set locale
    fallbackLocale: "hu",
    silentTranslationWarn: true,
    messages: messages
});
