<template>
    <div>
        <div class="row">
            <div
                class="col-lg-4"
                v-for="(item, index) in upperTwo"
                v-bind:key="'upper-' + index"
            >
                <div class="article-box my-4 mt-lg-0">
                    <div class="aspect43">
                        <b-img-lazy :src="item.main_image" />
                    </div>

                    <div class="mt-2">{{ item.publication_date }}</div>
                    <h3 class="title title-normal my-3">
                        {{ item.title }}
                    </h3>

                    <p class="text-justify mt-3 mb-1" v-html="item.lead"></p>
                    <router-link
                        :to="{
                            name: 'Article',
                            params: { slug: item.slug }
                        }"
                        class="stretched-link"
                        >{{ $t("Read more") }}</router-link
                    >
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div
                class="col-lg-4"
                v-for="(item, index) in model"
                v-bind:key="'news-' + index"
            >
                <div class="row my-3 position-relative">
                    <div class="col-6">
                        <div class="aspect169">
                            <b-img-lazy :src="item.main_image" />
                        </div>
                    </div>
                    <div class="col-6">
                        <div>{{ item.publication_date }}</div>
                        <h3 class="title title-small my-3">
                            {{ item.title }}
                        </h3>
                        <router-link
                            :to="{
                                name: 'Article',
                                params: { slug: item.slug }
                            }"
                            class="stretched-link"
                        ></router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-5">
            <router-link to="/news" class="btn btn-outline">{{
                $t("View all")
            }}</router-link>
        </div>
    </div>
</template>

<script>
import BaseNews from "@/components/BaseNews";
import ApiService from "@/core/services/api.service";

export default {
    name: "LatestNewsBlock",
    extends: BaseNews,
    methods: {
        getModel(page = 1) {
            this.upperTwo = [];
            this.model = [];
            ApiService.query(`/blog/get-list?page=${page}&per_page=12`).then(
                response => {
                    let index = 0;
                    this.meta = response.data.meta;
                    for (const it in response.data.data) {
                        if (index < 3) {
                            this.upperTwo.push(response.data.data[it]);
                        } else {
                            this.model.push(response.data.data[it]);
                        }
                        index++;
                    }
                }
            );
        }
    }
};
</script>
