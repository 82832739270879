<template>
    <div class="charity">
        <section>
            <div class="container">
                <div class="title-block title-block-no-top">
                    <div class="title title-big">{{ $t("Charity") }}</div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-5 text-center">
                        <div class="title title-big">
                            {{ model.title }}
                        </div>
                        <div class="date mb-5">
                            {{ model.publication_date }}
                        </div>
                        <div class="aspect169">
                            <b-img-lazy :src="model.image" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8 offset-xl-2">
                        <div class="lead" v-html="model.lead"></div>
                        <article v-html="model.content"></article>
                    </div>
                </div>
                <ContactUsBlock />
            </div>
        </section>
        <LatestNewsSubscribeBlock />
        <MoreCharityBlock :model="model.connected" />
    </div>
</template>

<script>
import LatestNewsSubscribeBlock from "@/components/LatestNewsSubscribeBlock";
import MoreCharityBlock from "@/components/MoreCharityBlock";
import BaseArticle from "@/components/BaseArticle";
import ApiService from "@/core/services/api.service";
import ContactUsBlock from "@/components/ContactUsBlock";
export default {
    name: "Charity",
    extends: BaseArticle,
    components: { ContactUsBlock, MoreCharityBlock, LatestNewsSubscribeBlock },
    methods: {
        getPage() {
            ApiService.query(`/charity/get/${this.$route.params.slug}`).then(
                resp => {
                    this.model = resp.data.data;
                    this.metaCreation(this.model);
                }
            );
        }
    }
};
</script>

<style scoped></style>
