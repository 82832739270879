<template>
    <section class="marble-section py-5">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="title title-big mb-5">
                        {{ $t("Sorry!") }}
                        <br />{{
                            $t("The page you’re looking for was not found")
                        }}
                    </div>
                    <router-link to="/" class="btn btn-outline">{{
                        $t("Go back to homepage")
                    }}</router-link>
                </div>
                <div class="col-md-6">
                    <b-img-lazy src="/img/404.svg" class="w-100 my-5" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "NotFound"
};
</script>

<style scoped></style>
