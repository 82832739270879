<template>
    <div class="designer">
        <section>
            <div class="container">
                <div class="title-block title-block-no-top">
                    <div class="title title-big">
                        {{ $t("About Master Designers") }}
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-6">
                        <div class="aspect11">
                            <b-img-lazy :src="model.main_image" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="title title-big mb-5">{{ model.name }}</div>

                        <strong v-html="model.title"></strong>

                        <ul
                            class="social-icon-list mt-3"
                            v-if="model && model.social"
                        >
                            <template v-for="(item, index) in model.social">
                                <li
                                    v-bind:key="'social-' + index"
                                    v-if="item !== null"
                                >
                                    <a :href="item" target="_blank"
                                        ><b-img-lazy :src="getSocial(index)" />
                                    </a>
                                </li>
                            </template>
                        </ul>

                        <p class="mt-5" v-html="model.lead"></p>
                    </div>
                </div>
            </div>
        </section>

        <section class="marble-section py-5" v-if="model.images">
            <div class="container">
                <div class="title-block">
                    <div class="title title-big">
                        {{ model.name }}{{ $t("’s Work") }}
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-12">
                        <div class="aspect169">
                            <b-img-lazy :src="images[actualImageIndex]" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div
                        class="col-md-3"
                        v-for="(item, index) in selectedFourImage"
                        v-bind:key="'four-image-' + index"
                    >
                        <div class="aspect11">
                            <b-img-lazy :src="item" />
                        </div>
                    </div>
                </div>
                <div
                    class="row align-items-center"
                    v-if="images && images.length > 0"
                >
                    <div class="pager text-center">
                        <a
                            href="#"
                            v-on:click.prevent="setIndex(actualImageIndex - 1)"
                            ><img src="/img/arrow_left.svg" class="arrow" />
                        </a>
                        {{ actualImageIndex + 1 }} / {{ images.length }}
                        <a
                            href="#"
                            v-on:click.prevent="setIndex(actualImageIndex + 1)"
                            ><img src="/img/arrow_right.svg" class="arrow" />
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container">
                <div class="row my-5">
                    <div class="col-md-8 offset-md-2">
                        <article v-html="model.content"></article>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container">
                <div class="title-block">
                    <div class="title title-big">
                        {{ model.name }}{{ $t("’s Trailer") }}
                    </div>
                </div>
                <div class="row mb-5" v-if="model.trailer">
                    <div class="col-md-12">
                        <b-embed
                            style="max-width: 100%;"
                            type="video"
                            aspect="16by9"
                            controls
                            :poster="model.trailer_image"
                        >
                            <source :src="model.trailer" type="video/mp4" />
                        </b-embed>
                    </div>
                </div>
            </div>
        </section>
        <ContactFormSpeaker />
        <ContactFormPresent />
    </div>
</template>

<script>
import ContactFormSpeaker from "@/components/ContactFormSpeaker";
import ContactFormPresent from "@/components/ContactFormPresent";
import BaseArticle from "@/components/BaseArticle";
import ApiService from "@/core/services/api.service";
export default {
    name: "Designer",
    extends: BaseArticle,
    components: { ContactFormPresent, ContactFormSpeaker },
    computed: {
        selectedFourImage: function() {
            let imgs = [];
            let indx = 0;
            for (const it in this.images) {
                if (indx > this.actualImageIndex && imgs.length < 4) {
                    imgs.push(this.images[it]);
                }
                indx++;
            }

            return imgs;
        }
    },
    data() {
        return {
            images: [],
            actualImageIndex: 0
        };
    },
    methods: {
        setIndex(fIndex) {
            if (fIndex < 0) {
                this.actualImageIndex = 0;
            } else if (fIndex > this.actualImageIndex.length - 1) {
                this.actualImageIndex = this.actualImageIndex.length - 1;
            } else {
                this.actualImageIndex = fIndex;
            }
        },
        getPage() {
            ApiService.query(`/designer/get/${this.$route.params.slug}`).then(
                resp => {
                    this.model = resp.data.data;
                    this.images = this.model.images;

                    let helper = JSON.parse(JSON.stringify(this.model));
                    helper.title = helper.name;
                    this.metaCreation(helper);
                }
            );
        },
        getSocial(fInd) {
            switch (fInd) {
                case "behance":
                    return "/img/behance_w.svg";
                case "instagram":
                    return "/img/insta_w.svg";
                case "facebook":
                    return "/img/facebook_w.svg";
                case "linkedin":
                    return "/img/linkdin_w.svg";
                case "web":
                    return "/img/www_w.svg";
            }
        }
    }
};
</script>

<style scoped></style>
