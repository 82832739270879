var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events"},[_c('section',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-block title-block-no-top"},[_c('h2',{staticClass:"title title-big"},[_vm._v(_vm._s(_vm.$t("Next events")))])]),_vm._l((_vm.model),function(item,index){return _c('div',{key:'events-' + index,staticClass:"row gx-0 my-4"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"aspect11"},[_c('b-img-lazy',{attrs:{"src":item.image}})],1)]),_c('div',{staticClass:"col-xl-7 black-section"},[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.start_date))]),_c('router-link',{staticClass:"title title-normal mb-4",staticStyle:{"cursor":"pointer"},attrs:{"to":{
                                name: 'EventPage',
                                params: { slug: item.slug }
                            }}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"tag":"p","to":{
                                name: 'EventPage',
                                params: { slug: item.slug }
                            }},domProps:{"innerHTML":_vm._s(item.lead)}}),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"title title-small"},[_vm._v(" "+_vm._s(_vm.$t("Performers"))+" ")]),_vm._l((item.performers),function(item2,index2){return [_c('router-link',{key:'perf-' + index + '-' + index2,attrs:{"to":{
                                            name: 'Designer',
                                            params: { slug: item2.slug }
                                        }}},[_vm._v(_vm._s(item2.name))]),_c('br',{key:'perf-br-' +
                                                index +
                                                '-' +
                                                index2})]})],2),_c('div',{staticClass:"col-md-7"},[(item.start_date_rfc)?_c('Countdown',{attrs:{"date":item.start_date_rfc}}):_vm._e(),(item.join_link)?_c('div',{staticClass:"d-grid"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                                            name: 'EventPage',
                                            params: { slug: item.slug }
                                        }}},[_vm._v(" "+_vm._s(_vm.$t("Join now"))+" ")])],1):_vm._e()],1)])],1)])])})],2)]),_c('PastEvents'),_c('ContactFormSpeaker'),_c('ContactFormPresent')],1)}
var staticRenderFns = []

export { render, staticRenderFns }