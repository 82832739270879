<template>
    <div
        class="row timer mt-5 mb-3"
        v-if="hours >= 0 && minutes >= 0 && seconds >= 0"
    >
        <div class="col">
            <div class="number">{{ days }}</div>
            <div class="number-title">{{ $t("Days") }}</div>
        </div>
        <div class="col">
            <div class="number">{{ hours }}</div>
            <div class="number-title">
                {{ $t("Hours") }}
            </div>
        </div>
        <div class="col">
            <div class="number">{{ minutes }}</div>
            <div class="number-title">
                {{ $t("Minutes") }}
            </div>
        </div>
        <div class="col">
            <div class="number">{{ seconds }}</div>
            <div class="number-title">
                {{ $t("Seconds") }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Countdown",
    mounted() {
        window.setInterval(() => {
            this.now = Math.trunc(new Date().getTime() / 1000);
        }, 1000);
    },
    props: {
        date: {
            type: String
        }
    },
    computed: {
        dateInMilliseconds() {
            return Math.trunc(Date.parse(this.date) / 1000);
        },
        seconds() {
            return (this.dateInMilliseconds - this.now) % 60;
        },
        minutes() {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
        },
        hours() {
            return (
                Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24
            );
        },
        days() {
            return Math.trunc(
                (this.dateInMilliseconds - this.now) / 60 / 60 / 24
            );
        }
    },
    data() {
        return {
            now: Math.trunc(new Date().getTime() / 1000)
        };
    }
};
</script>
