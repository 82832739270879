var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article"},[_c('section',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-block title-block-no-top"},[_c('div',{staticClass:"title title-big"},[_vm._v(_vm._s(_vm.$t("Search")))])]),_c('div',{staticClass:"row"},[_vm._l((_vm.model.news),function(item,index){return _c('div',{key:'search-news-' + index,staticClass:"col-lg-3"},[_c('div',{staticClass:"article-box my-4 mt-lg-0"},[_c('div',{staticClass:"aspect43"},[_c('b-img-lazy',{attrs:{"src":item.main_image}})],1),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(item.publication_date))]),_c('h3',{staticClass:"title title-normal my-3"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"text-justify mt-3 mb-1",domProps:{"innerHTML":_vm._s(item.lead)}}),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{
                                name: 'Article',
                                params: { slug: item.slug }
                            }}},[_vm._v(_vm._s(_vm.$t("Read more")))])],1)])}),_vm._l((_vm.model.tender),function(item,index){return _c('div',{key:'search-tenders-' + index,staticClass:"col-lg-3"},[_c('div',{staticClass:"article-box my-4 mt-lg-0"},[_c('div',{staticClass:"aspect43"},[_c('b-img-lazy',{attrs:{"src":item.image}})],1),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(item.publication_date))]),_c('h3',{staticClass:"title title-normal my-3"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{staticClass:"text-justify mt-3 mb-1",domProps:{"innerHTML":_vm._s(item.lead)}}),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{
                                name: 'Tender',
                                params: { slug: item.slug }
                            }}},[_vm._v(_vm._s(_vm.$t("Read more")))])],1)])}),_vm._l((_vm.model.charity),function(item,index){return _c('div',{key:'search-charity-' + index,staticClass:"col-lg-3"},[_c('div',{staticClass:"article-box my-4 mt-lg-0"},[_c('div',{staticClass:"aspect43"},[_c('b-img-lazy',{attrs:{"src":item.image}})],1),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(item.publication_date))]),_c('h3',{staticClass:"title title-normal my-3"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{staticClass:"text-justify mt-3 mb-1",domProps:{"innerHTML":_vm._s(item.lead)}}),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{
                                name: 'Charity',
                                params: { slug: item.slug }
                            }}},[_vm._v(_vm._s(_vm.$t("Read more")))])],1)])}),_vm._l((_vm.model.designer),function(item,index){return _c('div',{key:'search-designer-' + index,staticClass:"col-md-3 my-4"},[_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"aspect11"},[_c('b-img-lazy',{attrs:{"src":item.main_image}})],1),_c('div',{staticClass:"title title-normal mt-3"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{
                                name: 'Designer',
                                params: { slug: item.slug }
                            }}},[_vm._v(_vm._s(_vm.$t("View")))])],1)])}),_vm._l((_vm.model.event),function(item,index){return _c('div',{key:'search-events-' + index,staticClass:"col-md-12"},[_c('div',{staticClass:"row gx-0 my-4"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"aspect11"},[_c('b-img-lazy',{attrs:{"src":item.image}})],1)]),_c('div',{staticClass:"col-xl-7 black-section"},[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(item.start_date)+" ")]),_c('router-link',{staticClass:"title title-normal mb-4",staticStyle:{"cursor":"pointer"},attrs:{"to":{
                                        name: 'EventPage',
                                        params: { slug: item.slug }
                                    }}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"tag":"p","to":{
                                        name: 'EventPage',
                                        params: { slug: item.slug }
                                    }},domProps:{"innerHTML":_vm._s(item.lead)}}),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"title title-small"},[_vm._v(" "+_vm._s(_vm.$t("Performers"))+" ")]),_vm._l((item.performers),function(item2,index2){return [_c('router-link',{key:'perf-' +
                                                        index +
                                                        '-' +
                                                        index2,attrs:{"to":{
                                                    name: 'Designer',
                                                    params: {
                                                        slug: item2.slug
                                                    }
                                                }}},[_vm._v(_vm._s(item2.name))]),_c('br',{key:'perf-br-' +
                                                        index +
                                                        '-' +
                                                        index2})]})],2),_c('div',{staticClass:"col-md-7"},[(item.start_date_rfc)?_c('Countdown',{attrs:{"date":item.start_date_rfc}}):_vm._e(),(item.join_link)?_c('div',{staticClass:"d-grid"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                                                    name: 'EventPage',
                                                    params: {
                                                        slug: item.slug
                                                    }
                                                }}},[_vm._v(" "+_vm._s(_vm.$t("Join now"))+" ")])],1):_vm._e()],1)])],1)])])])})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }