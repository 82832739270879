<template>
    <div class="events">
        <section>
            <div class="container">
                <div class="title-block title-block-no-top">
                    <h2 class="title title-big">{{ $t("Next events") }}</h2>
                </div>
                <div
                    class="row gx-0 my-4"
                    v-for="(item, index) in model"
                    v-bind:key="'events-' + index"
                >
                    <div class="col-xl-5">
                        <div class="aspect11">
                            <b-img-lazy :src="item.image" />
                        </div>
                    </div>
                    <div class="col-xl-7 black-section">
                        <div class="p-4">
                            <div class="date">{{ item.start_date }}</div>
                            <router-link
                                class="title title-normal mb-4"
                                :to="{
                                    name: 'EventPage',
                                    params: { slug: item.slug }
                                }"
                                style="cursor: pointer"
                            >
                                {{ item.title }}
                            </router-link>

                            <router-link
                                tag="p"
                                :to="{
                                    name: 'EventPage',
                                    params: { slug: item.slug }
                                }"
                                style="cursor: pointer"
                                v-html="item.lead"
                            ></router-link>

                            <div class="row align-items-center">
                                <div class="col-md-5">
                                    <div class="title title-small">
                                        {{ $t("Performers") }}
                                    </div>
                                    <template
                                        v-for="(item2,
                                        index2) in item.performers"
                                    >
                                        <router-link
                                            v-bind:key="
                                                'perf-' + index + '-' + index2
                                            "
                                            :to="{
                                                name: 'Designer',
                                                params: { slug: item2.slug }
                                            }"
                                            >{{ item2.name }}</router-link
                                        >
                                        <br
                                            v-bind:key="
                                                'perf-br-' +
                                                    index +
                                                    '-' +
                                                    index2
                                            "
                                        />
                                    </template>
                                </div>
                                <div class="col-md-7">
                                    <Countdown
                                        :date="item.start_date_rfc"
                                        v-if="item.start_date_rfc"
                                    />
                                    <div class="d-grid" v-if="item.join_link">
                                        <router-link
                                            class="btn btn-primary"
                                            :to="{
                                                name: 'EventPage',
                                                params: { slug: item.slug }
                                            }"
                                        >
                                            {{ $t("Join now") }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <PastEvents />
        <ContactFormSpeaker />
        <ContactFormPresent />
    </div>
</template>

<script>
import PastEvents from "@/components/PastEvents";
import ContactFormSpeaker from "@/components/ContactFormSpeaker";
import ContactFormPresent from "@/components/ContactFormPresent";
import BaseNews from "@/components/BaseNews";
import ApiService from "@/core/services/api.service";
import Countdown from "@/components/Countdown";
export default {
    name: "Events",
    extends: BaseNews,
    components: {
        Countdown,
        ContactFormPresent,
        ContactFormSpeaker,
        PastEvents
    },
    methods: {
        getModel(page = 1) {
            page = 1;
            ApiService.query(`/event/next?page=${page}`).then(response => {
                this.meta = response.data.meta;
                this.model = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
