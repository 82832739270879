import { render, staticRenderFns } from "./ContactUsBlock.vue?vue&type=template&id=221a5b32&scoped=true&"
import script from "./ContactUsBlock.vue?vue&type=script&lang=js&"
export * from "./ContactUsBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221a5b32",
  null
  
)

export default component.exports