<template>
    <div id="app">
        <Header />
        <div class="content">
            <router-view />
        </div>
        <Partners />
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Partners from "@/components/Partners";
export default {
    name: "App",
    components: { Partners, Footer, Header }
};
</script>
