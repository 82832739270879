<template>
    <div class="row my-5">
        <div class="col-md-4 offset-md-4 text-center mb-5">
            <div class="title title-normal mb-3">{{ $t("Contact us") }}</div>
            <p>
                1139 Budapest, Röppentyű utca 65-67 <br />
                <a href="mailto:hello@master-designers.com"
                    >hello@master-designers.com</a
                >
                <br />
                <a href="tel:+36304975828">+36 30 497 5828</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactUsBlock"
};
</script>

<style scoped></style>
