<template>
    <div></div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "BaseSubscribe",
    data() {
        return {
            form: {
                firstname: null,
                lastname: null,
                email: null,
                phone_number: null,
                company_name: null,
                aszf: null,
                message: null,
                instagram_user: null,
                facebook_page: null
            },
            endPoint: null
        };
    },
    methods: {
        submitForm() {
            ApiService.post(this.endPoint).then(() => {
                this.form = {
                    firstname: null,
                    lastname: null,
                    email: null,
                    phone_number: null,
                    company_name: null,
                    aszf: null,
                    message: null,
                    instagram_user: null,
                    facebook_page: null
                };
            });
        }
    }
};
</script>
