<template>
    <div class="article">
        <section>
            <div class="container">
                <div class="title-block title-block-no-top">
                    <div class="title title-big">{{ $t("Search") }}</div>
                </div>
                <div class="row">
                    <div
                        class="col-lg-3"
                        v-for="(item, index) in model.news"
                        v-bind:key="'search-news-' + index"
                    >
                        <div class="article-box my-4 mt-lg-0">
                            <div class="aspect43">
                                <b-img-lazy :src="item.main_image" />
                            </div>

                            <div class="mt-2">{{ item.publication_date }}</div>
                            <h3 class="title title-normal my-3">
                                {{ item.title }}
                            </h3>

                            <p
                                class="text-justify mt-3 mb-1"
                                v-html="item.lead"
                            ></p>
                            <router-link
                                :to="{
                                    name: 'Article',
                                    params: { slug: item.slug }
                                }"
                                class="stretched-link"
                                >{{ $t("Read more") }}</router-link
                            >
                        </div>
                    </div>
                    <div
                        class="col-lg-3"
                        v-for="(item, index) in model.tender"
                        v-bind:key="'search-tenders-' + index"
                    >
                        <div class="article-box my-4 mt-lg-0">
                            <div class="aspect43">
                                <b-img-lazy :src="item.image" />
                            </div>

                            <div class="mt-2">{{ item.publication_date }}</div>
                            <h3 class="title title-normal my-3">
                                {{ item.name }}
                            </h3>

                            <p
                                class="text-justify mt-3 mb-1"
                                v-html="item.lead"
                            ></p>
                            <router-link
                                :to="{
                                    name: 'Tender',
                                    params: { slug: item.slug }
                                }"
                                class="stretched-link"
                                >{{ $t("Read more") }}</router-link
                            >
                        </div>
                    </div>
                    <div
                        class="col-lg-3"
                        v-for="(item, index) in model.charity"
                        v-bind:key="'search-charity-' + index"
                    >
                        <div class="article-box my-4 mt-lg-0">
                            <div class="aspect43">
                                <b-img-lazy :src="item.image" />
                            </div>

                            <div class="mt-2">{{ item.publication_date }}</div>
                            <h3 class="title title-normal my-3">
                                {{ item.name }}
                            </h3>

                            <p
                                class="text-justify mt-3 mb-1"
                                v-html="item.lead"
                            ></p>
                            <router-link
                                :to="{
                                    name: 'Charity',
                                    params: { slug: item.slug }
                                }"
                                class="stretched-link"
                                >{{ $t("Read more") }}</router-link
                            >
                        </div>
                    </div>
                    <div
                        class="col-md-3 my-4"
                        v-for="(item, index) in model.designer"
                        v-bind:key="'search-designer-' + index"
                    >
                        <div class="position-relative">
                            <div class="aspect11">
                                <b-img-lazy :src="item.main_image" />
                            </div>
                            <div class="title title-normal mt-3">
                                {{ item.name }}
                            </div>
                            <router-link
                                :to="{
                                    name: 'Designer',
                                    params: { slug: item.slug }
                                }"
                                class="stretched-link"
                                >{{ $t("View") }}</router-link
                            >
                        </div>
                    </div>
                    <div
                        class="col-md-12"
                        v-for="(item, index) in model.event"
                        v-bind:key="'search-events-' + index"
                    >
                        <div class="row gx-0 my-4">
                            <div class="col-xl-5">
                                <div class="aspect11">
                                    <b-img-lazy :src="item.image" />
                                </div>
                            </div>
                            <div class="col-xl-7 black-section">
                                <div class="p-4">
                                    <div class="date">
                                        {{ item.start_date }}
                                    </div>
                                    <router-link
                                        class="title title-normal mb-4"
                                        :to="{
                                            name: 'EventPage',
                                            params: { slug: item.slug }
                                        }"
                                        style="cursor: pointer"
                                    >
                                        {{ item.title }}
                                    </router-link>

                                    <router-link
                                        tag="p"
                                        :to="{
                                            name: 'EventPage',
                                            params: { slug: item.slug }
                                        }"
                                        style="cursor: pointer"
                                        v-html="item.lead"
                                    ></router-link>

                                    <div class="row align-items-center">
                                        <div class="col-md-5">
                                            <div class="title title-small">
                                                {{ $t("Performers") }}
                                            </div>
                                            <template
                                                v-for="(item2,
                                                index2) in item.performers"
                                            >
                                                <router-link
                                                    v-bind:key="
                                                        'perf-' +
                                                            index +
                                                            '-' +
                                                            index2
                                                    "
                                                    :to="{
                                                        name: 'Designer',
                                                        params: {
                                                            slug: item2.slug
                                                        }
                                                    }"
                                                    >{{
                                                        item2.name
                                                    }}</router-link
                                                >
                                                <br
                                                    v-bind:key="
                                                        'perf-br-' +
                                                            index +
                                                            '-' +
                                                            index2
                                                    "
                                                />
                                            </template>
                                        </div>
                                        <div class="col-md-7">
                                            <Countdown
                                                :date="item.start_date_rfc"
                                                v-if="item.start_date_rfc"
                                            />
                                            <div
                                                class="d-grid"
                                                v-if="item.join_link"
                                            >
                                                <router-link
                                                    class="btn btn-primary"
                                                    :to="{
                                                        name: 'EventPage',
                                                        params: {
                                                            slug: item.slug
                                                        }
                                                    }"
                                                >
                                                    {{ $t("Join now") }}
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Countdown from "@/components/Countdown";

export default {
    name: "Search",
    components: { Countdown },
    data() {
        return {
            model: {
                news: [],
                tender: [],
                charity: [],
                designer: [],
                event: []
            }
        };
    },
    methods: {
        search() {
            if (!this.$route.query.s) return false;
            ApiService.query(`/page/search/${this.$route.query.s}`).then(
                resp => {
                    this.model = resp.data;
                }
            );
        }
    },
    beforeMount() {
        this.search();
    },
    watch: {
        $route: {
            handler: function() {
                this.search();
            },
            deep: true
        }
    }
};
</script>
