<template>
    <section class="py-5">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-3">
                    <div class="title title-big">{{ $t("Partners") }}</div>
                </div>
                <div
                    class="col-4 col-lg"
                    v-for="(item, index) in partners"
                    v-bind:key="'partners-' + index"
                >
                    <a :href="item.link" target="_blank">
                        <b-img-lazy
                            class="w-100 p-4"
                            :src="item.image"
                            :alt="item.name"
                        />
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Partners",
    data() {
        return {
            partners: []
        };
    },
    beforeMount() {
        ApiService.get(`/partner/get`).then(resp => {
            this.partners = resp.data.data;
        });
    }
};
</script>

<style scoped></style>
