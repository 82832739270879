<template>
    <div class="about">
        <section>
            <div class="container">
                <div class="title-block title-block-no-top">
                    <div class="title title-big">{{ $t("About us") }}</div>
                </div>
                <div class="row">
                    <div class="col-xl-8 offset-xl-2 mb-5">
                        <div class="title title-medium">
                            {{ pageData.name }}
                        </div>
                    </div>
                </div>
                <div class="row" v-if="pageData.og_image">
                    <div class="col-12 mb-5 text-center">
                        <div class="aspect169">
                            <b-img-lazy :src="pageData.og_image" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8 offset-xl-2">
                        <div
                            class="lead"
                            v-html="pageData.meta_description"
                        ></div>
                        <article v-html="pageData.content"></article>
                    </div>
                </div>
                <ContactUsBlock />
            </div>
        </section>
        <ContactFormSpeaker />
        <ContactFormPresent />
    </div>
</template>
<script>
import ContactFormSpeaker from "@/components/ContactFormSpeaker";
import ContactFormPresent from "@/components/ContactFormPresent";
import ContactUsBlock from "@/components/ContactUsBlock";
export default {
    name: "About",
    props: ["pageData"],
    components: { ContactUsBlock, ContactFormPresent, ContactFormSpeaker }
};
</script>
