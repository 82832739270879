<template>
    <div class="row" v-if="model.length > 0">
        <div class="col-lg-8">
            <div class="mdSlider">
                <div v-for="(item, index) in model" v-bind:key="'md-' + index">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="aspect11">
                                <b-img-lazy
                                    :src="item.main_image"
                                    class="img img-fluid"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div
                                class="title title-normal bordered-designer-title mb-3"
                            >
                                {{ item.name }}
                            </div>
                            <p v-html="item.lead"></p>
                            <router-link
                                :to="{
                                    name: 'Designer',
                                    params: { slug: item.slug }
                                }"
                                class="mb-3 d-block"
                                >{{ $t("Read more") }}</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="mdSlider-buttons">
                <div
                    v-for="(item, index) in model"
                    v-bind:key="'md-buttons-' + index"
                >
                    <div class="aspect11">
                        <b-img class="img img-fluid" :src="item.main_image" />
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 px-2">
                <router-link to="/master-designers" class="btn btn-outline">{{
                    $t("View all")
                }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import BaseNews from "@/components/BaseNews";
import ApiService from "@/core/services/api.service";

export default {
    name: "MasterDesignersBlock",
    extends: BaseNews,
    methods: {
        getModel(page = 1) {
            ApiService.query(
                `/designer/get?page=${page}&per_page=4&orderBy=rand`
            )
                .then(response => {
                    this.meta = response.data.meta;
                    this.model = response.data.data;
                })
                .finally(() => {
                    $(document).ready(function() {
                        $(".mdSlider-buttons").slick({
                            arrows: false,
                            infinite: true,
                            slidesToScroll: 4,
                            slidesToShow: 4,
                            asNavFor: ".mdSlider",
                            focusOnSelect: true
                        });
                        $(".mdSlider").slick({
                            arrows: false,
                            autoplay: true
                            //dots: true
                        });
                    });
                });
        }
    }
};
</script>
