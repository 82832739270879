<template>
    <footer class="py-5">
        <div class="container">
            <div class="text-center mb-4">
                <b-img-lazy src="/img/logo.svg" class="footer-logo" />
            </div>
            <div class="row align-items-center">
                <div
                    class="col-xl text-center my-2"
                    v-for="(item, index) in menu.items"
                    v-bind:key="'footer-menu-' + index"
                >
                    <router-link :to="item.link">{{ item.title }}</router-link>
                </div>
                <div class="col-xl text-center my-2">
                    <a
                        href="https://www.instagram.com/vesta_home_master_designers/"
                        target="_blank"
                        ><b-img-lazy
                            src="/img/insta_w.svg"
                            class="social-icon"
                        />
                    </a>
                    <a
                        href="https://www.facebook.com/vestahome.masterdesigners"
                        target="_blank"
                        ><b-img-lazy
                            src="/img/facebook_w.svg"
                            class="social-icon"
                        />
                    </a>
                    <a
                        href="https://www.youtube.com/watch?v=Vxr8sEflcgU&list=PLVvA2jUjZsyUrf3M-I_CWKx6MjKJhYsA_&ab_channel=VestaHomeOfficial"
                        target="_blank"
                        ><b-img-lazy
                            src="/img/youtube_w.svg"
                            class="social-icon"
                        />
                    </a>
                </div>
                <div
                    class="col-xl text-center my-2 mx-auto"
                    style="max-width: 200px;"
                >
                    <router-link to="/"
                        ><b-img-lazy src="/img/vestahome.svg" />
                    </router-link>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Footer",
    data() {
        return {
            menu: {
                items: []
            }
        };
    },
    beforeMount() {
        ApiService.get(`/menu/get/2`).then(resp => {
            this.menu = resp.data.data;
        });
    }
};
</script>

<style scoped></style>
