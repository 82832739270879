<template>
    <section>
        <div class="container">
            <div class="title-block title-block-no-top">
                <h2 class="title title-big">Tenders</h2>
            </div>
            <div class="row">
                <div
                    class="col-md-4 mt-4"
                    v-for="(item, index) in model"
                    v-bind:key="'tenders-' + index"
                >
                    <div class="article-box">
                        <div>{{ item.publication_date }}</div>
                        <h3 class="title title-normal my-3">
                            {{ item.name }}
                        </h3>
                        <div class="aspect169">
                            <b-img-lazy :src="item.image" />
                        </div>

                        <p
                            class="text-justify mt-3 mb-1"
                            v-html="item.lead"
                        ></p>
                        <router-link
                            :to="{
                                name: 'Tender',
                                params: { slug: item.slug }
                            }"
                            class="stretched-link"
                            >{{ $t("Read more") }}</router-link
                        >
                    </div>
                </div>
            </div>
            <div class="text-center mt-5">
                <router-link to="/tenders" class="btn btn-outline">{{
                    $t("View all")
                }}</router-link>
            </div>
        </div>
    </section>
</template>

<script>
import BaseNews from "@/components/BaseNews";
import ApiService from "@/core/services/api.service";

export default {
    name: "TendersBlock",
    extends: BaseNews,
    methods: {
        getModel(page = 1) {
            ApiService.query(`/tender/get?page=${page}&per_page=6`).then(
                response => {
                    this.meta = response.data.meta;
                    this.model = response.data.data;
                }
            );
        }
    }
};
</script>

<style scoped></style>
